import React, { useEffect, useState } from 'react';
import { Button, Row, Col, Image } from 'antd';
import './index.css'; // Make sure to create and import your CSS file for custom styles
// @ts-ignore
import Navbar from "../../components/navbar"
// @ts-ignore
import backgroundImage from '../../assets/images/mobile/mobile_page_backgroud.jpg';
// @ts-ignore
import browserOpens from '../../assets/images/mobile/browser_opens.png';
import { APP_DOWNLOAD_URL } from "../../commons/constant/constants";

const AppDownload: React.FC = () => {

    
    const [overlayVisible, setOverlayVisible] = useState(true);

    const handleOverlayClick = () => {
      setOverlayVisible(false);
    };

    useEffect(()=>{
      if (typeof window !== "undefined") {
        const userAgent = navigator.userAgent.toLowerCase();
        setOverlayVisible(userAgent.includes('micromessenger'));
      }
    },[])
  return (
    <>
    <Navbar siteTitle={`里德笔记`} />
    {overlayVisible && (
        <div className='overlay' id="overlay" onClick={handleOverlayClick}>
          <img src={browserOpens} alt="Close" id="closeImage"/>
        </div>
      )}
    <div className="page-container" >
        <div className="header-section">
        <h1>一单一结  自助提现<br></br><br></br>热爱生活  自由工作</h1>
        <Button type="primary" size="large" className="download-button" onClick={()=>{window.location.href = APP_DOWNLOAD_URL}}>
          下载Android版本
        </Button>
        <p className="subtext">请点击下面的网址即可复制到手机浏览器访问</p>
        <p className="subtext">www.lidebiji.com</p>
      </div>
      
      <div className="tools-section">
        <Image preview={false} src={backgroundImage}></Image>
        
      </div>
    </div>
    </>
    
    
  );
};

export default AppDownload;
